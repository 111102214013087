@import url('https://fonts.googleapis.com/css?family=Poppins');
body {
  font-family: 'Poppins';
  min-height: 100vh;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
}
.App {
  text-align: center;
  background: #000000;
  min-height: 100vh;
}

.nosafari{
  -moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
  outline: none;

  background-color: rgb(30,30,30);
  text-align-last: left;
  padding-left: 15px;
  font-size: 14px;
}

input:focus {outline:none;}

.html{

  background: #000000;
}
.Getfy-logo {
  text-align: left;
  height: 20px;
  pointer-events: none;
  margin-top: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.animationShake{
  animation: shake 0.5s; 
  animation-iteration-count: infinite; 
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}